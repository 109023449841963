import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import AppContext from "context/AppContext";
import { deleteAuthToken } from "models/auth";
import Spinner from "components/common/spinner";
import Template from "templates/default";

import GET_ME from "graphql/auth/queries/getMe";

const withAuth = (WrapperComponent) => {
  const Wrapper = (props) => {
    const [user, setUser] = useState();
    const router = useRouter();
    const landingPageRoute = "/";
    const signinRoute = "/signin";
    const registerRoute = "/register";

    const [isLoaded, setIsLoaded] = useState(
      router.pathname === landingPageRoute ||
        router.pathname === signinRoute ||
        router.pathname === registerRoute
    );

    const { data, error } = useQuery(GET_ME, {
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if (data?.me) {
        setUser(data.me);

        // REDIRECT SIGNIN TO HOME PAGE
        if (
          router.pathname === signinRoute ||
          router.pathname === registerRoute
        ) {
          router.push("/home");
        } else {
          setIsLoaded(true);
        }
      }
    }, [data]);

    useEffect(() => {
      if (error) {
        setUser(null);
        deleteAuthToken();

        // PREVENT INFINITE RELOAD IF IT IS THE SIGNIN PAGE
        if (
          router.pathname !== signinRoute &&
          router.pathname !== registerRoute &&
          router.pathname !== landingPageRoute
        ) {
          router.push(`${signinRoute}?redirect_to=${router.asPath}`);
        } else {
          setIsLoaded(true);
        }
      }
    }, [error]);

    const onLogout = () => {
      setUser(null);
      deleteAuthToken();
      router.reload();
    };

    if (!isLoaded)
      return (
        <Template hideMenu hideAuthLinks>
          <div className="text-center w-full">
            <Spinner />
          </div>
        </Template>
      );

    return (
      <AppContext.Provider
        value={{
          user: user,
          isAuthenticated: !!user,
          setUser: setUser,
          logout: onLogout,
        }}>
        <WrapperComponent {...props} />
      </AppContext.Provider>
    );
  };

  return Wrapper;
};

export default withAuth;
