import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";

import Template from "templates/landingPage";
import Container from "components/common/container";
import Button from "components/common/button";

import WithAuth from "hoc/auth";

const IndexPage = () => {
  const router = useRouter();

  return (
    <>
      <Template>
        <Head>
          <meta
            name="description"
            content="Easynutri é o app de atendimento nutricional que te permite focar no paciente. Simples, sem burocracias e gratuíto."
          />
          <title>Easynutri - Seu app de atendimento nutricional.</title>
        </Head>
        <div>
          <Container>
            <div className="flex pb-4 sm:pb-16 flex-col-reverse sm:flex-row">
              <div className="flex-1 py-4 flex justify-center items-start flex-col items-center sm:items-start text-center sm:text-left">
                <h1 className="font-mono leading-8 sm:leading-10 text-gray-900 text-2xl sm:text-3xl font-bold">
                  Atendimento nutricional
                  <br />
                  de forma simples e objetiva
                </h1>
                <p className="w-11/12 sm:w-9/12 mt-2 text-gray-900">
                  Easynutri é o app de atendimento nutricional que te permite
                  focar no paciente. Simples, sem burocracias e sem custos. Isso
                  mesmo, é <strong>grátis</strong>.
                </p>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/register`);
                  }}
                  appearance="red"
                  className="mt-4"
                  auto
                  text="Começar a usar"
                />
              </div>

              <div className="flex-1 relative">
                <div className="px-4 flex sm:hidden">
                  <Image
                    src="/images/landing/main-mobile.png"
                    layout="intrinsic"
                    width={296}
                    height={197}
                    quality={100}
                    alt="Imagem com funcionalidades do Easynutri"
                  />
                </div>
                <div className="px-4 hidden sm:flex">
                  <Image
                    src="/images/landing/main.png"
                    layout="intrinsic"
                    width={627}
                    height={418}
                    quality={100}
                    alt="Imagem com funcionalidades do Easynutri"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="pt-4 sm:pt-0">
          <div className="sm:hidden">
            <Image
              src="/images/landing/patients-mobile.png"
              width={390}
              height={273}
              quality={100}
              className="w-full h-auto"
              alt="Imagem da tela de Pacientes do Easynutri"
            />
          </div>
          <Container>
            <div className="sm:flex">
              <div className="flex-1 relative">
                <div className="hidden pr-4 sm:flex">
                  <Image
                    src="/images/landing/patients.png"
                    width={535}
                    height={452}
                    quality={100}
                    alt="Imagem da tela de Pacientes do Easynutri"
                  />
                </div>
              </div>
              <div className="flex-1 pt-0 pb-8 md:py-8 flex justify-center items-start flex-col">
                <p className="text-sm text-blue-500 bg-white inline px-6 py-1 rounded-full border border-blue-500">
                  Gerencie seus pacientes
                </p>
                <h2 className="sm:w-5/6 xl2:w-4/6 mt-4 sm:mt-6 font-mono text-xl sm:text-2xl leading-7 sm:leading-8 text-gray-900  font-bold">
                  Easynutri ajuda você a gerenciar sua lista de pacientes de um
                  jeito simples.
                </h2>
                <p className="sm:w-10/12 xl2:w-9/12 mt-2 text-gray-900">
                  Cadastre e atualize os dados dos seus pacientes sem burocracia
                  e sem campos desnecessários, além disso visualize a evolução e
                  histórico dos pacientes facilmente.
                </p>
              </div>
            </div>
          </Container>
        </div>
        <div className="hidden sm:flex justify-center">
          <Image
            src="/images/landing/arrow-2.png"
            width={130}
            height={136}
            quality={100}
            alt="Imagem de uma seta"
          />
        </div>
        <div className="mt-4 sm:mt-8">
          <div className="sm:hidden">
            <Image
              src="/images/landing/schedule-mobile.png"
              width={390}
              height={280}
              quality={100}
              className="w-full h-auto"
              alt="Imagem da tela de Agendamentos do Easynutri"
            />
          </div>
          <Container>
            <div className="sm:flex">
              <div className="flex-1 pt-4 pb-8 md:py-8 flex justify-center items-start flex-col">
                <p className="text-sm text-orange-500 bg-white inline px-6 py-1 rounded-full border border-orange-500">
                  Gerencie sua agenda
                </p>
                <h2 className="sm:w-9/12 mt-4 md:mt-6 font-mono text-xl sm:text-2xl leading-7 sm:leading-8 text-gray-900 font-bold">
                  Descomplique sua agenda.
                  <br />
                  Adicione os próximos atendimentos em sua agenda sem
                  complicação.
                </h2>
                <p className="sm:w-9/12 mt-2 text-gray-900">
                  Gerencie seus atendimentos futuros e adicione ao seu Google
                  Calendar facilmente através do Easynutri.
                </p>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/register`);
                  }}
                  appearance="black"
                  className="mt-4"
                  auto
                  text="Criar minha conta"
                />
              </div>

              <div className="flex-1 relative">
                <div className="px-4 hidden sm:flex">
                  <Image
                    src="/images/landing/schedule.png"
                    width={614}
                    height={418}
                    quality={100}
                    alt="Imagem da tela de Agendamentos do Easynutri"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="hidden sm:flex justify-center">
          <Image
            src="/images/landing/arrow-1.png"
            width={69}
            height={93}
            quality={100}
            alt="Imagem de uma seta"
          />
        </div>
        <div className="mt-8 sm:mt-0">
          <div className="sm:hidden">
            <Image
              src="/images/landing/records-mobile.png"
              width={390}
              height={322}
              quality={100}
              className="w-full h-auto"
              alt="Imagem da tela de Atendimentos do Easynutri"
            />
          </div>
          <Container>
            <div className="sm:flex">
              <div className="flex-1 relative">
                <div className="pr-4 hidden sm:flex">
                  <Image
                    src="/images/landing/records.png"
                    width={535}
                    height={377}
                    quality={100}
                    alt="Imagem da tela de Atendimentos do Easynutri"
                  />
                </div>
              </div>
              <div className="flex-1 pt-0 pb-8 md:py-8 flex justify-center items-start flex-col">
                <p className="text-sm text-green-600 bg-white inline px-6 py-1 rounded-full border border-green-600">
                  Gerencie seus atendimentos
                </p>
                <h2 className="sm:w-9/12 2xl:w-8/12 mt-4 md:mt-6 font-mono text-xl sm:text-2xl leading-7 sm:leading-8 text-gray-900 font-bold">
                  Atendimentos com foco no paciente através de uma interface
                  intuítiva.
                </h2>
                <p className="sm:w-9/12 mt-2 text-gray-900">
                  O atendimento deve ser focado no paciente e não perdendo tempo
                  no sistema que você utiliza. Por isso criamos uma interface
                  simples e intuítiva para que você foque no que realmente
                  importa, o seu paciente.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <div className="bg-gray-light sm:bg-white mt-6 sm:mt-10">
          <Container className="relative">
            <div className="absolute bottom-[-22px] left-16 hidden sm:block">
              <Image
                src="/images/landing/footer.png"
                width={138}
                height={195}
                quality={100}
                alt="Ilustração de uma pessoa"
              />
            </div>
            <div className="py-8 sm:py-10">
              <div className="flex items-center flex-col ">
                <h2 className="text-center sm:w-6/12 xl:w-5/12 2xl:w-4/12 font-mono leading-6 text-gray-900 text-lg sm:leading-8 sm:text-xl font-semibold">
                  Comece a usar o Easynutri agora mesmo e ganhe tempo para focar
                  seu atendimento naquilo que é mais importante, o seu paciente.
                  <br />
                  <br />
                  Aproveite, é grátis.
                </h2>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/register`);
                  }}
                  appearance="black"
                  className="mt-8"
                  auto
                  text="Começar a usar"
                />
              </div>
            </div>
          </Container>
        </div>
      </Template>
    </>
  );
};

export default WithAuth(IndexPage);
