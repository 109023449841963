import Header from "components/header";
import Footer from "components/footer";
import Meta from "components/meta";

const Template = ({ children }) => (
  <div className="bg-white">
    <Meta />
    <div className="min-h-screen">
      <Header />
      <main>{children}</main>
    </div>
    <Footer />
  </div>
);

export default Template;
